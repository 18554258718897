
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { StrategyOptimize } from "api/models";

@Component({})
export default class StrategyOptimizerDetails extends Vue {
  @Prop()
  strategyOptimize?: StrategyOptimize;
}
