
import Component from "vue-class-component";
import Vue from "vue";
import { strategyOptimizerApi } from "@/plugins/api";
import { delay, map, switchMap } from "rxjs/operators";
import { StrategyOptimize, StrategyOptimizeResult } from "api/models";
import {
  mdiCalendar,
  mdiClockOutline,
  mdiDotsVertical,
  mdiPencil,
  mdiPlay,
  mdiRocketLaunchOutline,
  mdiStop,
  mdiTrashCanOutline,
} from "@mdi/js";
import OptimizeResultTable from "@/components/StrategyOptimizer/OptimizeResultTable.vue";
import { Subscription } from "rxjs";
import StrategyOptimizeResultStatusEnum from "@/entity/map/StrategyOptimizeResultStatusEnum";
import StrategyOptimizeStatusEnum from "@/entity/map/StrategyOptimizeStatusEnum";
import StrategyOptimizerDetails from "@/components/StrategyOptimizer/StrategyOptimizerDetails.vue";
import { PostAppStrategyoptimizerOptimizeRequest } from "../../api";

@Component({
  components: {
    StrategyOptimizerDetails,
    OptimizeResultTable,
  },
})
export default class StrategyOptimizer extends Vue {
  strategyOptimizeId = parseInt(this.$router.currentRoute.params.strategyOptimizeId);

  strategyOptimize: StrategyOptimize = null;

  icons = {
    mdiPlay: mdiPlay,
    mdiStop: mdiStop,
    mdiRocketLaunchOutline: mdiRocketLaunchOutline,
    mdiCalendar: mdiCalendar,
    mdiClockOutline: mdiClockOutline,
    mdiDotsVertical: mdiDotsVertical,
    mdiTrashCanOutline: mdiTrashCanOutline,
    mdiPencil: mdiPencil,
  };

  results: Array<StrategyOptimizeResult> = [];
  pendingResults: Array<StrategyOptimizeResult> = [];
  runningResults: Array<StrategyOptimizeResult> = [];
  stoppedResults: Array<StrategyOptimizeResult> = [];
  doneResults: Array<StrategyOptimizeResult> = [];
  emptyResults: Array<StrategyOptimizeResult> = [];

  timerSubscription: Subscription;

  get statusIsPending() {
    return this.strategyOptimize && this.strategyOptimize.status === StrategyOptimizeStatusEnum.STATUS_PENDING;
  }

  get statusIsRunning() {
    return this.strategyOptimize && this.strategyOptimize.status === StrategyOptimizeStatusEnum.STATUS_RUNNING;
  }

  get statusIsStopped() {
    return this.strategyOptimize && this.strategyOptimize.status === StrategyOptimizeStatusEnum.STATUS_STOPPED;
  }

  get statusIsDone() {
    return this.strategyOptimize && this.strategyOptimize.status === StrategyOptimizeStatusEnum.STATUS_DONE;
  }

  get hasRunButton() {
    return this.statusIsPending;
  }

  get hasRestartButton() {
    return this.statusIsStopped;
  }

  get hasPending() {
    return this.statusIsStopped;
  }

  get hasStopButton() {
    return this.statusIsRunning;
  }

  get hasLoading() {
    return this.statusIsRunning;
  }

  mounted() {
    this.timerSubscription = this.timer$().subscribe();
  }

  destroyed() {
    this.timerSubscription?.unsubscribe();
  }

  timer$() {
    return this.optimizeGet$().pipe(
      switchMap(() => {
        return this.optimizeGetResults$();
      }),
      delay(2000),
      switchMap(() => this.timer$())
    );
  }

  optimizeGet$() {
    return strategyOptimizerApi
      .getAppStrategyoptimizerGetone({
        strategyOptimizeId: this.strategyOptimizeId,
      })
      .pipe(
        map((next) => {
          this.strategyOptimize = next;
        })
      );
  }

  optimizeGetResults$() {
    return strategyOptimizerApi
      .getAppStrategyoptimizerGetresults({
        strategyOptimizeId: this.strategyOptimizeId,
      })
      .pipe(
        map((next) => {
          this.results = next;
          this.pendingResults = this.results.filter(
            (result) => result.status === StrategyOptimizeResultStatusEnum.STATUS_PENDING
          );
          this.runningResults = this.results.filter(
            (result) => result.status === StrategyOptimizeResultStatusEnum.STATUS_RUNNING
          );
          this.stoppedResults = this.results.filter(
            (result) => result.status === StrategyOptimizeResultStatusEnum.STATUS_STOPPED
          );
          this.doneResults = this.results.filter(
            (result) =>
              result.status === StrategyOptimizeResultStatusEnum.STATUS_DONE &&
              result.strategyOptimizeResultItems?.length > 0
          );
          this.emptyResults = this.results.filter(
            (result) =>
              result.status === StrategyOptimizeResultStatusEnum.STATUS_DONE &&
              !(result.strategyOptimizeResultItems?.length > 0)
          );
          if (this.statusIsDone) {
            this.timerSubscription.unsubscribe();
          }

          return next;
        })
      );
  }

  optimizePost() {
    const request: PostAppStrategyoptimizerOptimizeRequest = {
      strategyOptimizeId: this.strategyOptimizeId,
    };

    strategyOptimizerApi.postAppStrategyoptimizerOptimize(request).subscribe();
  }

  stop() {
    strategyOptimizerApi
      .postAppStrategyoptimizerStop({
        strategyOptimizeId: this.strategyOptimizeId,
      })
      .subscribe();
  }

  restart() {
    strategyOptimizerApi
      .postAppStrategyoptimizerRestart({
        strategyOptimizeId: this.strategyOptimizeId,
      })
      .subscribe();
  }

  navigateToFinetune(result) {
    this.$router.push({
      name: "strategyOptimizerFinetuneById",
      params: { strategyOptimizeResultItemId: result.strategyOptimizeResultItems[0].id },
    });
  }

  editStrategyOptimize() {
    this.$router.push({
      name: "strategyOptimizerEdit",
      params: {
        strategyOptimizeId: this.strategyOptimizeId,
      },
    });
  }

  deleteStrategyOptimize() {
    if (confirm("Do you really want to delete this?")) {
      strategyOptimizerApi
        .deleteAppStrategyoptimizerRemove({
          strategyOptimizeId: this.strategyOptimizeId,
        })
        .subscribe(() => {
          this.$router.push({
            name: "strategyOptimizerList",
          });
        });
    }
  }
}
