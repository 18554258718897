
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { StrategyOptimizeResult } from "api/models";
import { mdiArrowBottomRightThick, mdiArrowTopRightThick, mdiDotsVertical, mdiRobotLove, mdiTune } from "@mdi/js";
import { botApi } from "@/plugins/api";
import { adjectives, animals, uniqueNamesGenerator } from "unique-names-generator";

@Component({})
export default class OptimizeResultTable extends Vue {
  @Prop()
  results?: Array<StrategyOptimizeResult>;

  icons = {
    mdiArrowTopRightThick: mdiArrowTopRightThick,
    mdiArrowBottomRightThick: mdiArrowBottomRightThick,
    mdiRobotLove: mdiRobotLove,
    mdiDotsVertical: mdiDotsVertical,
    mdiTune: mdiTune,
  };

  getHeaders(): Array<{ text: string; value: string; sortable?: boolean }> {
    return [
      { text: "Long / short", value: "isLong" },
      { text: "Market", value: "marketOhlcv.market.name" },
      { text: "Profit", value: "profit", sortable: false },
      { text: "Sum value", value: "strategyOptimizeResultItems[0].sumValue" },
      { text: "Cagr", value: "strategyOptimizeResultItems[0].cagr" },
      { text: "Max drawdown", value: "strategyOptimizeResultItems[0].maxDrawDown" },
      { text: "Sort value", value: "strategyOptimizeResultItems[0].sortValue" },
      { text: "Actions", value: "actions", sortable: false },
    ];
  }

  clickRow(result) {
    this.$emit("click-row", result);
  }

  createBot(id: number) {
    const name = uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      separator: " ",
      length: 2,
      style: "capital",
    });

    botApi
      .postAppBotCreatefromoptimizeresultitem({
        botCreateFromOptimizeResultIdRequest: { optimizeResultItemId: id, name: name },
      })
      .subscribe((next) => {
        this.$router.push({ name: "bot", params: { strategyRunId: next.id } });
      });
  }
}
