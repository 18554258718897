export default class StrategyOptimizeStatusEnum {
  static STATUS_PENDING = 0;
  static STATUS_RUNNING = 1;
  static STATUS_STOPPED = 2;
  static STATUS_DONE = 3;

  static TEXTS = {
    0: "Pending",
    1: "Running",
    2: "Stopped",
    3: "Done",
  };
}
